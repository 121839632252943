//token
export function localGetToken() {
  const key = 'token'
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}
export function localSetToken(value){
  localSet('token',value)
}
//用户信息
/*
* accid: "hl13261860787bk"
authStatus: 1
headImg: "http://hulujianyi-download.oss-cn-zhangjiakou.aliyuncs.com/huluToolNew/huludefaultavatar.png"
name: null
nickName: "用户185359615"
openid: null
token: "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJ7XCJhY2NvdW50XCI6XCJITDEzMjYxODYwNzg3QktcIixcImNsaWVudFR5cGVcIjo0LFwic3lzdGVtQ2xpZW50VHlwZVwiOlwiQ1wiLFwidXNlckFjY291bnRJZFwiOjE4fSIsImlzcyI6IkhVTFUtVE9PTCIsImlhdCI6MTY2MDA1MDA5MSwiZXhwIjoxNjYyNjQyMDkxfQ.pWjIzXq4vjSRPRclgLaSiq9CyrFprJn_FHLWr9vEIFE"
yxToken: "d50a9610c53c3d99c4194b89fbcea979"
[[Prototype]]: Object
* */
export function localGetUserInfo() {
  const key = 'user-info'
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}
export function localSetUserinfo(value){
  localSet('user-info',value)

}

export function localGet (key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export function localSet (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove (key) {
  window.localStorage.removeItem(key)
}

// 判断内容是否含有表情字符，现有数据库不支持。
export function hasEmoji (str = '') {
  const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
  return str.match(reg) && str.match(reg).length
}

// 单张图片上传
export const uploadImgServer = 'http://backend-api-02.newbee.ltd/manage-api/v1/upload/file'
// 多张图片上传
export const uploadImgsServer = 'http://backend-api-02.newbee.ltd/manage-api/v1/upload/files'

export const pathMap = {
  loginIndex: '登录',
  introduce: '系统介绍',
  dashboard: '大盘数据',
  add: '添加商品',
  swiper: '轮播图配置',
  hot: '热销商品配置',
  new: '新品上线配置',
  recommend: '为你推荐配置',
  category: '分类管理',
  level2: '分类二级管理',
  level3: '分类三级管理',
  good: '商品管理',
  guest: '会员管理',
  order: '订单管理',
  order_detail: '订单详情',
  account: '修改账户'
}

export const isMobile = () => {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
  );
}